html {
  font-size: 1em;
}
html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
}
body {
  background: $background;
  background-position: center center;
  background-size: cover;
  color: $fontColor;
}
html,
body,
input,
textarea,
select {
  font-family: $fontFamily;
}
h1 small {
  font-size: 0.7em;
}
h4 small {
  font-size: 0.9em;
  font-weight: normal;
}

.split-panel {
  display: flex;
  flex-direction: row;
}
.split-pane {
  height: 100vh;
  position: relative;
}
.split-pane:nth-child(2) {
  border-left: 2px solid rgba(0, 0, 0, 0.1);
  background: rgba(0,0,0,0.1);
}
.split-pane.switch-wrapper > div {
  position: relative;
}
.split-page {
  padding: 80px 20px 0;
  width: 500px;
}
.split-pane-toggle {
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 45px;
  background: #fff;
  right: 0;
  bottom: 0;
  font-size: 26px;
  color: $primaryColor;
}

.single-device {
  font-size: 28px;
  line-height: 30px;
  margin: 0 0 20px;
}
.single-device small {
  font-size: 0.4em;
  display: block;
}
.display-device-name {
  position: fixed;
  bottom: 0;
  left: 10px;
  display: block;
  padding: 10px;
}

.main-logo {
  font-size: 34px;
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}
.page-center {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.page-title {
  text-align: center;
  margin: 0 0 10px;
  text-shadow: 1px 1px 2px $primaryColor;

  h1 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  h2 {
    font-size: 1.8em;
  }
}
.page {
  display: flex;
  flex-direction: column;
}
.page-header {
  position: relative;
  padding: 0;
  height: 60px;
  text-align: center;
  background: darken($primaryColor, 0.5);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}
.page-header h1 {
  line-height: 60px;
  font-size: 28px;
}
.page-header .header-form h1 {
  display: inline;
}
.page-header .header-form form {
  display: inline-block;
  width: 25%;
  vertical-align: top;
  position: relative;
  margin: 10px 0 0 10px;
}
.page-header .header-form input {
  display: inline-block;
  margin: 0;
  padding: 2px 15px;
  vertical-align: top;
}
.page-header .header-form .search-form-btn {
  width: 40px;
}

.page-content {
  display: flex;
}
.page-content-main {
  padding: 30px;
  flex: 1;
  height: unquote("calc(100vh - 60px)");
  overflow: auto;
}
.page-content-narrow {
  padding: 20px 20px 0;
  margin: 0;
}
.page-content-header {
  display: flex;
  margin: 0 0 20px;
  justify-content: center;
}
.page-content-header div:nth-child(2) {
  flex: 1;
}
.page-content-aside {
  background: rgba(0, 0, 0, 0.1);
  width: 350px;
  box-shadow: -2px 0 3px rgba(0, 0, 0, 0.2);
}
.page-content-main,
.page-content-aside {
  height: unquote("calc(100vh - 60px)");
}

.with-footer .page-content-main,
.with-footer .page-content-aside {
  height: unquote("calc(100vh - 260px)");
}

.page-content-aside-header {
  position: relative;
  margin: 0;
  padding: 15px 15px 10px;
  height: 50px;
}
.page-content-aside-header-title {
  font-size: 20px;
  font-weight: bold;
}
.page-content-aside-header-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.page-content-aside-content {
  height: unquote("calc(100vh - 60px - 50px)");
  overflow-x: hidden;
  overflow-y: scroll;
}
.page-content-aside-table {
  width: 100%;
  border: none;
  border-spacing: 0;
  font-size: 16px;
}
.page-content-aside-table thead {
  background: rgba(0, 0, 0, 0.3);
}
.page-content-aside-table th,
.page-content-aside-table td {
  line-height: 20px;
  padding: 5px 10px;
}
.page-content-aside-table th {
  padding: 10px;
}
.page-content-aside-table td {
  font-size: 14px;
}
.page-content-aside-table tbody tr {
  background: rgba(255, 255, 255, 0.1);
}
.page-content-aside-table tbody tr:nth-child(odd) {
  background: rgba(0, 0, 0, 0.1);
}

.light-table {
  width: 100%;
  border: none;
  border-spacing: 0;
  font-size: 18px;
  border: 2px solid #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}
.light-table thead,
.light-table thead th {
  background: #199586;
}
.light-table th,
.light-table td {
  line-height: 20px;
  padding: 8px 10px;
}
.light-table th {
  padding: 10px;
}
.light-table td {
}
.light-table tbody tr {
  background: transparent;
  color: #fff;
}
.light-table tbody tr:nth-child(odd) {
  background: rgba(255, 255, 255, 0.65);
  color: darken($primaryColor, 10);
}
.light-table tbody tr:nth-child(odd) a {
  color: darken($primaryColor, 10);
}
.light-table .table-sort-arrow {
  position: relative;
}
.light-table .table-sort-arrow i {
  position: absolute;
  left: -20px;
  top: 5px;
}

.light-table-specimen {
  overflow: hidden;
}
.light-table-specimen th span {
  display: block;
  position: relative;
  height: 30px;
}
.light-table-specimen th small {
  position: absolute;
  text-align: left;
  bottom: 22px;
  left: 0;
  display: block;
  font-size: 12px;
  transform-origin: bottom;
  transform: rotate(-60deg);
  width: 75px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.light-table-specimen th,
.light-table-specimen td {
  position: relative;
  overflow: visible;
}
.light-table-specimen tbody tr {
  background: transparent;
  color: #fff;
}
.light-table-specimen tbody tr:nth-child(odd) {
  background: rgba(255, 255, 255, 0.65);
  color: darken($primaryColor, 10);
}
.light-table-specimen tbody tr:nth-child(odd) a {
  color: darken($primaryColor, 10);
}
.light-table-specimen .table-sort-arrow {
  position: absolute;
  bottom: 0;
  left: 5px;
}
.light-table-specimen .table-sort-arrow i {
  position: relative;
  left: 0;
  top: 0;
}
.light-table-hilight {
  background: rgba(255, 255, 255, 0.3) !important;
}

.form-table {
  width: 100%;
  border: 2px solid #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  border-spacing: 0;
  overflow: hidden;
  font-size: 18px;
}
.form-table-radius {
  border-radius: $boxBorderRadius $boxBorderRadius 0 0;
}
.form-table thead {
  background: #fff;
  color: $primaryColor;
}
.form-table thead th {
  padding: 5px 0;
  text-align: center;
}
.form-table tbody td {
  padding: 3px 20px;
  vertical-align: middle;
}
.form-table-closer tbody td {
  padding-right: 5px;
  padding-left: 5px;
}
.form-table tbody tr:last-child td,
.form-table tbody tr:last-child th {
  padding-bottom: 15px;
}
.form-table tbody tr:first-child td,
.form-table tbody tr:first-child th {
  padding-top: 15px;
}
.form-table .left-border {
  border-left: 2px solid #fff;
}
.form-table .table-input {
  font-size: 18px;
  line-height: 2em;
  display: block;
  margin: 0;
  width: 100%;
  padding: 0 0;
  border: none;
  outline: none;
  background: transparent;
  color: #fff;
  border-bottom: 2px solid #fff;
}
.form-table .table-input:focus,
.form-table .table-input:active {
  outline: none;
}

.form-table select {
  border: none;
  font-size: 16px;
  line-height: 34px;
  padding: 0 30px 0 0;
  margin: 0 30px 0 0;
  background-color: transparent;
  background-image: url("../images/arrow-down-white.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 10px auto, 100%;
  text-align: left;
  border-radius: none;
  color: #fff;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
option,
optgroup {
  -webkit-appearance: none;
  background: #fff;
  color: darken($primaryColor, 10);
}

.table-input-wrapper {
  font-size: 18px;
  line-height: 2em;
  color: #fff;
  border-bottom: 2px solid #fff;
}
.form-table .table-radio {
  display: inline-block;
  width: auto;
  font-size: 18px;
  line-height: 2em;
  padding: 0 30px 0 0;
}

.form-table-middle {
  border-top: 0;
}
.form-table-append {
  background: $secondaryColor;
  border-top: 0;
  border-radius: 0 0 $boxBorderRadius $boxBorderRadius;
}
.form-table-append > tbody > tr > td {
  padding: 0 !important;
}

.form-table-dark {
  width: 100%;
  border: 2px solid $primaryColor;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  border-spacing: 0;
  overflow: hidden;
  font-size: 18px;
  margin: 0 0 20px;
}
.form-table-dark thead {
  background: $primaryColor;
  color: #fff;
}
.form-table-dark thead th {
  padding: 5px 0;
  text-align: center;
}
.form-table-dark tbody td {
  padding: 3px 5px;
  vertical-align: middle;
  line-height: 25px;
}
.form-table-dark tbody tr:last-child td,
.form-table-dark tbody tr:last-child th {
  padding-bottom: 15px;
}
.form-table-dark tbody tr:first-child td,
.form-table-dark tbody tr:first-child th {
  padding-top: 15px;
}
.form-table-dark .left-border {
  border-left: 2px solid $primaryColor;
}
.form-table-dark .table-input {
  font-size: 18px;
  line-height: 25px;
  display: block;
  margin: 0;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  color: $primaryColor;
  border-bottom: 2px solid $primaryColor;
}
.form-table-dark .table-input:focus,
.form-table-dark .table-input:active {
  outline: none;
}

.search-form {
  position: relative;
  min-width: 400px;
}
.search-form-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0;
  border-radius: 30px;
  opacity: 1;
  width: 50px;
  text-align: center;
  border: none;
  color: $primaryColor;
  background: transparent;
  border: 1px solid transparent;
  outline: none;
}

.page-content-padded {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 25px 0;
}

.link-page-back {
  left: 0;
  top: 0;
  position: absolute;
  background: url(../images/icon-left.svg) no-repeat center left;
  background-size: auto 100%;
  line-height: 40px;
  height: 40px;
  padding: 0 0 0 50px;
  margin: 10px;
  border: none;
  color: #fff;
  outline: none;
}

.flash-message {
  background: #222;
  padding: 5px;
  clear: both;
  margin: 0 0 20px 0;
  border-radius: 5px;
}
.flash-message-show {
  display: block;
}
.flash-message-hide {
  display: none;
}
.flash-message-alert {
  background: $alertColor;
}
.flash-message-info {
  background: $infoColor;
}
.flash-message-success {
  background: $successColor;
}

.loading {
  display: block;
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 9999;
}
.inline-loading {
  display: block;
  //background: rgba(0,0,0,.1);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 9999;
}

.logout-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px;
  z-index: 1;
}
.logout-wrapper .menu-topbar {
  margin: 0 0 0 10px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: none;
  color: #ffffff;
  border: none;
  padding: 0 5px;
  display: inline-block;
  border-radius: 20px;
  font-weight: bold;
  text-transform: uppercase;
  opacity: 0.8;
  white-space: nowrap;
  text-align: center;
}
.logout-wrapper .menu-topbar a,
.logout-wrapper .menu-topbar button,
.logout-wrapper .menu-topbar time {
  cursor: pointer;
  background: transparent;
  border-radius: 30px;
  display: inline-block;
  padding: 5px 0;
  margin: 0;
  font-size: 16px;
  line-height: 30px;
  min-width: 30px;
  text-align: center;
  border: none;
  text-transform: uppercase;
  color: #fff;
  outline: none;
}
.logout-wrapper .menu-topbar time {
  font-size: 16px;
  padding: 5px;
  cursor: text;
}
.logout-wrapper .menu-topbar time:first-child {
  padding-left: 11px;
}
.spacer {
  display: inline-block;
  width: 30px;
}
.fa-exclamation-circle {
  color: red;
}

.modal {
  background: rgba(0, 0, 0, 0.65);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
  font-size: 18px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.modal-body {
  width: 400px;
  border-radius: $boxBorderRadius;
  background: #fff;
  color: $primaryColor;
  overflow: hidden;

  box-shadow: $inputShadow;
}
.modal-header {
  background: $primaryLightColor;
  color: $primaryLightColorTint;
  text-align: center;
  font-size: 20px;
  padding: 12px 0 8px;
  font-weight: bold;
}
.modal-content {
  padding: 20px 20px 30px;
}

.small-action-box {
  border: 1px solid #fff;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  font-size: 22px;
  margin: 0 0 20px;
}
.small-action-box p {
  margin-bottom: 0;
}
.small-action-box p:last-child {
  margin-bottom: 0;
}
.small-action-box-image {
  background: $primaryColor;
  line-height: 30px;
  font-size: 20px;
  padding: 10px 10px;
  color: #ffff;
  text-align: center;
}
.small-action-box-content {
  padding: 0;
  flex: 1;
}

.switch-wrapper {
  position: relative;
  width: 100%;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}

.no-connection-alert {
  background: #a7232a;
  color: #fff;
  font-size: 14px;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  font-weight: normal;
  line-height: 1.8em;
}

.popup-alert-box {
  content: "";
  display: block;
  position: fixed;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.popup-alert-box > span:before {
  content: "ALERT";
  background: #d55c67;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  height: 22px;
  display: block;
  padding: 10px 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.popup-alert-box > span > i {
  padding: 7px 15px 0;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 20px;
  line-height: 22px;
  font-weight: bold;
  cursor: pointer;
  z-index: 2;
  font-style: normal;
}
.popup-alert-box > span {
  position: absolute;
  display: block;
  border-radius: 15px;
  top: 30%;
  left: 50%;
  min-width: 450px;
  transform: translate(-50%, -50%);
  background: #fff;
  overflow: hidden;
  padding: 50px 20px 15px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  color: #000;
  white-space: pre-line;
}

.popup-confirm-box {
  content: "";
  display: block;
  position: fixed;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.popup-confirm-box > div:before {
  content: "PLEASE CONFIRM";
  background: $primaryColor;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  height: 22px;
  display: block;
  padding: 10px 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.popup-confirm-box > div > i {
  padding: 7px 15px 0;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 20px;
  line-height: 22px;
  font-weight: bold;
  cursor: pointer;
  z-index: 2;
  font-style: normal;
}
.popup-confirm-box > div {
  position: absolute;
  display: block;
  border-radius: 15px;
  top: 30%;
  left: 50%;
  min-width: 450px;
  transform: translate(-50%, -50%);
  background: #fff;
  overflow: hidden;
  padding: 60px 20px 15px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  color: #000;
  white-space: pre-line;
}

.popup-confirm-box button {
  padding: 5px 30px;
  line-height: 1.8em;
  margin: 10px 10px 0;
  border: none;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  outline: none;
}

.popup-confirm-box button.popup-confirm-btn {
  background: $primaryColor;
  color: $primaryTint;
}


.popup-prompt-box {
  content: "";
  display: block;
  position: fixed;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.popup-prompt-box > div:before {
  content: "PLEASE ENTER";
  background: $primaryColor;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  height: 22px;
  display: block;
  padding: 10px 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.popup-prompt-box > div > i {
  padding: 7px 15px 0;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 20px;
  line-height: 22px;
  font-weight: bold;
  cursor: pointer;
  z-index: 2;
  font-style: normal;
}
.popup-prompt-box > div {
  position: absolute;
  display: block;
  border-radius: 15px;
  top: 30%;
  left: 50%;
  min-width: 450px;
  transform: translate(-50%, -50%);
  background: #fff;
  overflow: hidden;
  padding: 60px 20px 15px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  color: #000;
  white-space: pre-line;
}

.popup-prompt-box input[type=text] {
  border: 1px solid #ccc;
  margin: 5px 1px;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  line-height: 1.8em;
  text-transform: uppercase;
}

.popup-prompt-box button {
  padding: 5px 30px;
  line-height: 1.8em;
  margin: 10px 10px 0;
  border: none;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  outline: none;
}

.popup-prompt-box button.popup-prompt-btn {
  background: $primaryColor;
  color: $primaryTint;
}




.page-unit-name {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 3px;
  font-size: 14px;
}
